<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-8 offset-md-2">
        <div class="jumbotron text-center">
          <div v-if="loadingFlipbase">
            <loader />
          </div>
          <div id="video-player-element" v-if="!loadingFlipbase && id"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flipbaseMixin from '../../mixins/flipbase';

export default {
  mixins: [flipbaseMixin],
  mounted() {
    const { playerId } = this.$route.query;
    if (!playerId) {
      this.$notify({
        type: 'error',
        message: this.$t('notifications.missingVideoId'),
        position: 'bottom-right'
      });
      return this.$router.push('/auth/login');
    }
    this.lazyLoadFlipbasePlayer().then(() => {
      setTimeout(() => {
        this.flipbaseplayer = new FlipbasePlayer({
          video_id: this.$route.params.id,
          player_id: playerId,
          selector: 'video-player-element'
        });
        this.flipbaseplayer.mount();
      }, 600);
    });
  },
  data() {
    return {
      id: this.$route.params.id,
      playerId: this.$route.query.playerId
    };
  }
};
</script>

<style>
.flipbase-video-player {
  margin: auto !important;
}
</style>
